var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.survey)?_c('v-card',{attrs:{"flat":"","tile":"","height":"100%","img":_vm.survey.background || _vm.theme.image,"color":_vm.theme.colors.background}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"pa-0 ma-0",attrs:{"wrap":""}},[(_vm.survey.entry)?_c('v-flex',{staticClass:"pa-4 ma-4 mb-0",style:({
          backgroundColor: _vm.theme.colors.background,
          color: _vm.theme.colors.text,
        }),attrs:{"xs12":""}},[_c('div',{staticClass:"text-center pt-4"},[_c('v-chip',{staticClass:"elevation-1",attrs:{"pill":"","label":"","color":"secondary"}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.surveyType.icon)}}),_vm._v(" "+_vm._s(_vm.surveyType.title)+" ")],1)],1),(_vm.survey.logo)?_c('div',{staticClass:"text-center pt-4"},[(_vm.survey.entry.image)?_c('v-img',{attrs:{"contain":"","src":_vm.survey.entry.image,"max-height":"64"}}):_c('v-img',{attrs:{"contain":"","src":_vm.survey.logo,"max-height":"64"}})],1):_vm._e(),_c('div',{staticClass:"text-h3 text-center py-4",domProps:{"textContent":_vm._s(_vm.survey.title)}}),(_vm.survey.entry.description)?_c('div',{staticClass:"text-body-2 text-center py-2",domProps:{"textContent":_vm._s(_vm.survey.entry.description)}}):_vm._e(),_c('div',{staticClass:"text-center py-2"},[_c('v-chip',{staticClass:"elevation-1",attrs:{"pill":"","label":"","color":_vm.theme.colors.background,"text-color":_vm.theme.colors.text}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.surveyDuration)+"min ")],1)],1),_c('div',{staticClass:"align-center text-center py-4"},[(_vm.survey.status === 'Active')?_c('v-btn',{attrs:{"large":"","min-width":"150","color":_vm.theme.colors.highlight}},[_vm._v(_vm._s(_vm.survey.button))]):(_vm.survey.status === 'Draft')?_c('v-alert',{attrs:{"dense":"","text":"","color":"warning"}},[_vm._v(" This survey is not active yet ")]):_c('v-alert',{attrs:{"dense":"","text":"","color":"error"}},[_vm._v(" This survey has been closed ")])],1),_c('div',{staticClass:"align-center text-center pb-2"},[_c('span',{staticClass:"text-body-2"},[_c('v-icon',{staticStyle:{"color":"inherit"},attrs:{"small":""}},[_vm._v("mdi-incognito")]),_vm._v(" Your feedback is anonymous ")],1)])]):_vm._e(),_vm._l((_vm.survey.questions),function(question,questionIndex){return _c('v-flex',{key:questionIndex,staticClass:"pa-4 ma-4 mb-0",style:({
          backgroundColor: _vm.theme.colors.background,
          color: _vm.theme.colors.text,
        }),attrs:{"xs12":""}},[_c('div',{staticClass:"d-flex align-start"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"tile":"","size":"32","color":_vm.theme.colors.highlight},domProps:{"textContent":_vm._s(questionIndex + 1)}}),_c('v-flex',{staticClass:"text-h4",domProps:{"textContent":_vm._s(question.title)}}),(question.category)?_c('v-chip',{staticClass:"elevation-1",attrs:{"small":"","label":"","dark":"","color":"secondary"},domProps:{"textContent":_vm._s(question.category)}}):_vm._e()],1),(question.description)?_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"text-body-1",domProps:{"textContent":_vm._s(question.description)}})]):_vm._e(),_c('div',{staticClass:"pa-0 ma-0 mt-4"},[_c(question.type + 'ItemView',{key:questionIndex,tag:"component",style:({ color: _vm.theme.colors.text }),attrs:{"theme":_vm.theme,"readonly":true,"value":_vm.answers[questionIndex],"settings":question.settings,"choices":question.choices,"color":_vm.theme.colors.highlight},on:{"update:value":function($event){return _vm.$set(_vm.answers, questionIndex, $event)}}})],1)])}),(_vm.survey.exit)?_c('v-flex',{staticClass:"pa-4 ma-4",style:({
          backgroundColor: _vm.theme.colors.background,
          color: _vm.theme.colors.text,
        }),attrs:{"xs12":""}},[_c('div',{staticClass:"text-center pt-4"},[(_vm.survey.exit.image)?_c('v-img',{attrs:{"contain":"","src":_vm.survey.exit.image,"max-height":"64"}}):_c('v-icon',{attrs:{"size":"64","color":_vm.theme.colors.highlight}},[_vm._v("mdi-thumb-up-outline")])],1),_c('div',{staticClass:"text-h3 text-center py-4"},[_vm._v("Thank You")]),_c('div',{staticClass:"text-body-2 text-center pb-4",domProps:{"textContent":_vm._s(_vm.survey.exit.description)}}),_c('div',{staticClass:"text-body-2 text-center pb-4"},[_c('v-btn',{attrs:{"text":"","link":"","to":{ name: 'survey-results', params: { id: _vm.id } },"color":_vm.theme.colors.highlight}},[_vm._v(" See Survey Results ")])],1)]):_vm._e()],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }